<template>
  <div class="search-content">
    <div v-for="(subContent, i) in content?.subContents" v-bind:key="i" 
      class="search-content-sub"
      :class="{ 'listDisabled': wideIndex > -1 && wideIndex !== i }">
      <div class="header">
        <div class="title" @click="onSort(i, 0)">
          <span>
            {{
              this.$i18n.locale == "en"
                ? subContent.englishName
                : subContent.name
            }}
          </span>
          <span style="margin-left: 4px;">[{{ showSelect[i]?.length ?? 0 }}]</span>
          <span v-if="sortIndex.contentIndex === i && sortIndex.headerIndex === 0 && sortIndex.isAES" class="material-symbols-outlined">arrow_drop_down</span>
          <span v-if="sortIndex.contentIndex === i && sortIndex.headerIndex === 0 && !sortIndex.isAES" class="material-symbols-outlined">arrow_drop_up</span>
        </div>
        <div v-for="(desc, j) in subContent.desc" class="desc" @click="onSort(i, 1+j)">
          <span>
            {{
              this.$i18n.locale == "en"
                ? desc.englishName
                : desc.name
            }}
          </span>
          <span v-if="sortIndex.contentIndex === i && sortIndex.headerIndex === 1+j && sortIndex.isAES" class="material-symbols-outlined">arrow_drop_down</span>
          <span v-if="sortIndex.contentIndex === i && sortIndex.headerIndex === 1+j && !sortIndex.isAES" class="material-symbols-outlined">arrow_drop_up</span>
        </div>
        <button
          class="button-wide-open"
          :class="{ 'hidden': content?.subContents.length === 1 || (wideIndex > -1 && wideIndex !== i) }"
          @click.stop="onWide($event, i)"
        >
          <img
            src="@/assets/img/arrowLeftRight1.png"
            alt="펼치기"
          />
        </button>
      </div>
      <Select 
        :multiple="multiple"
        :data="showSelect[i]"
        :desc="subContent?.desc?.length"
        :focus="focusIndex === i"
        :title="true"
        @change="(selectData) => onChange(selectData, content.code, subContent.code, i)"
        @click="(data) => content.type === 3 && onSubDataShow(data, i+1)"
        @ondblclick="() => $emit('ondblclick')"
        @enter="() => $emit('enter')"
        @onContextmenu="(event, data) => $emit('onContextmenu', event, data, i)"
        />
    </div>
    <!-- Weekly (주별 업데이트 데이터 분석) 달력 -->
    <Calendar v-if="calendar"></Calendar>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import Select from "@/containerss/Select.vue";
import Calendar from "@/components/Calendar.vue";

export default defineComponent({
  name: "SearchContent",
  props: {
    multiple: {
      type: Boolean
    },
    content: {
      type: Object
    },
    searchTextVal: {
      type: String
    },
    calendar: {
      type: Boolean
    }
  },
  components: {
    Select,
    Calendar,
  },
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const { content, searchTextVal } = toRefs(props);

    const showSelect = ref([]);
    const selectedList = ref([]);
    const focusIndex = ref(-1);
    const sortIndex = ref({ contentIndex: -1, headerIndex: -1, isAES: true });
    const wideIndex = ref(-1);

    const init = () => {
      if (!content.value) return;
      
      showSelect.value = content.value.list.map((list, i) => {
        if (content.value.type === 3 && (searchTextVal.value??'') == '' && i !== 0) return [];
        else return list.list || list;
      });

      selectedList.value = [];
      sortIndex.value = { contentIndex: -1, headerIndex: -1, isAES: true };
      focusIndex.value = -1;

      // console.log('showSelect', showSelect.value);
      
      emit('change', []);
    };

    onMounted(() => {

      init();
    });

    watch([content], () => {
      // console.log('content', content.value);
      // console.log('searchTextVal', searchTextVal.value);

      init();
    });

    const onSort = (contentIndex, headerIndex) => {
      const list = showSelect.value[contentIndex];
      const isAES = sortIndex.value.contentIndex === contentIndex && sortIndex.value.headerIndex === headerIndex ? !sortIndex.value.isAES : true;

      list.sort((data1, data2) => {
        let name = 'name';
        if (headerIndex === 0) name = locale.value == "en" ? 'englishName' : 'name';
        else name = (locale.value == "en" ? 'englishDesc' : 'desc') + headerIndex;

        return isAES ? data1[name] > data2[name] ? 1 : -1 : data1[name] > data2[name] ? -1 : 1;
      });

      sortIndex.value = { contentIndex, headerIndex, isAES };
    }

    const onChange = (selectData, pCode, code, i) => {
      focusIndex.value = i;
      selectedList.value = selectData.map(data => ({
        ...data,
        pCode,
        code,
        // id: data.id,
        // name: data.name,
        // englishName: data.englishName,
        type: 0,
      }));
      emit('change', selectedList.value, code, pCode, i);
    }

    const onSubDataShow = (data, i) => {
      for(var j = i ; j < content.value.subContents.length ; j++) {
        if (j === i) showSelect.value[j] = content.value.list[i].list.filter(list => list.parentId === data.id);
        else showSelect.value[j] = [];
      }
      if (sortIndex.value.contentIndex >= i) sortIndex.value = { contentIndex: -1, headerIndex: -1, isAES: true };
    }

    const onWide = (event, i) => {
      if (wideIndex.value === i) wideIndex.value = -1;
      else wideIndex.value = i;
    }

    return {
      showSelect,
      selectedList,
      focusIndex,
      sortIndex,
      wideIndex,

      onSort,
      onChange,
      onSubDataShow,
      onWide,
    };
  },
});
</script>

<style scoped>
.search-content {
  display: flex;
  flex: 1 1 auto;
  min-height: 150px;
  max-width: calc(100vw - 776px);
  min-width: 100%;
  padding: 10px 0px;
}
@media screen and (max-width: 1440px) {
  .search-content {
    max-width: 664px;
  }
}
.search-content-sub {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}

.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header .title {
  flex: 1 1 60%;
}

.header .material-symbols-outlined {
  margin-left: 4px;
  font-size: 26px;
  color: rgb(185, 185, 185);
  vertical-align: top;
}

.header .desc {
  flex: 1 1 20%;
  padding-left: 6px;
  border-left: var(--border-line);
}

.listDisabled {
  flex: 0 0 3%;
  width: 3%;
}

.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  /* background-color: #8d8d8d; */
  /* border-radius: 50%; */
  z-index: 10;
}


.select {
  flex: 1 1 0;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  padding-top: 4px;
  overflow: auto;
  
  font-size: 13px;
  line-height: 15px;

  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.select :deep(.option) {
  display: flex;
  height: 23px;
  margin: 1px 0;
  padding: 4px 6px;
}
</style>
