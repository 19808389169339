let CONSTANTS = {
  // 측정치 코드 배열
  CONSTANT_METRICS_CODES: [
    "M001",
    "M002",
    "M003",
    "M008",
    "M009",
    "M010",
    "M013",
    "M014",
    "M015",
    "M027",
  ],

  // 측정치 Share 포함 코드 ID 배열 : 코드 확인 후 수정 필요
  CONSTANT_METRICS_SHARE_IDS: [
    "19",
    "36",
    "38",
    "40",
    "42",
    "43",
    "58",
    "60",
    "46",
    "48",
    "50",
    "52",
    "54",
    "56",
    "62",
    "63",
    "70",
    "71",
  ],

  // Sales 측정치 M001
  CONSTANT_METRICS_SALES_CODE: "M001",

  // Weekly 측정치 M002
  CONSTANT_METRICS_WEEKLY_CODE: "M002",

  // Dosage 측정치 M013
  CONSTANT_METRICS_DOSAGE_CODE: "M013",

  // Non-reimbursement 측정치 M003
  CONSTANT_METRICS_NONREIMB_CODE: "M003",

  // Non-reimbursement 측정치 M014
  CONSTANT_METRICS_VACCINESALES_CODE: "M014",

  // 기간 코드 배열
  CONSTANT_PERIOD_CODES: ["C001", "C019", "C062", "C065", "C082", "C098"],

  // Sales 기간 C001
  CONSTANT_PERIOD_CODE: "C001",
  // 월간 Trend를 위한 기간 [월] 코드 CS006 CS007
  CONSTANT_TREND_MONTHLY_CODES: ["CS006", "CS007"],
  // 분기 CS006
  CONSTANT_QUARTER_PERIOD_CODES: "CS006",
  // 월 CS007 코드
  CONSTANT_MONTH_PERIOD_CODES: "CS007",
  // 기간 컨텍스트 메뉴에서 더 보여줘야 할 분기 CS006 월 CS007 코드
  CONSTANT_CUSTMORE_PERIOD_CODES: ["CS006", "CS007"],

  // Weekly 기간 C019
  CONSTANT_PERIOD_WEEKLY_CODE: "C019",

  // 급여구분 C010
  CONSTANT_REIMBURSEMENT_CODE: "C010",

  // 병상 C012
  CONSTANT_BED_CODE: "C012",

  // 성별 C018
  CONSTANT_GENDER_CODE: "C018",

  // 성분 C006
  CONSTANT_MOLECULE_CODE: ["C006", "C058"],
  // 성분 전체 CS123
  CONSTANT_MOLECULE_TOTAL_CODE: ["CS123", "CS158"],
  // 성분(용량) 전체 CS122
  CONSTANT_MOLECULE_VOLUME_CODE: ["CS122", "CS157"],

  // 식약처 C007
  CONSTANT_KFDA_CODE: "C007",

  // 연령 C017
  CONSTANT_AGE_CODE: ["C017"/*, "C059"*/],
  // 연령 세부 코드 CS041
  CONSTANT_AGE_DETAIL_CODE: ["CS041", "CS147"],

  // 제조사 C002
  CONSTANT_MANUFACTURER_CODE: ["C002", "C056"],

  // 제품(약품) C004
  CONSTANT_PRODUCT_CODE: ["C004", "C055", "C060",  "C061"],

  // 제형 C008
  CONSTANT_DOSEFORM_CODE: "C008",

  // 종별 C011
  CONSTANT_CHANNEL_CODE: "C011",

  // 지역 C014
  CONSTANT_REGIONS_CODE: "C014",
  // 대지역
  CONSTANT_REGIONS_LARGE_CODE: "CS013",
  // 소지역
  CONSTANT_REGIONS_SMALL_CODE: "CS014",

  // 진료과 C015
  CONSTANT_SPECIALTY_CODE: "C015",
  // 진료과 SubContentCode CS027
  CONSTANT_SPECIALTY_SUBCONTENT_CODE: "CS027",
  // 세부진료과 C015
  CONSTANT_SPECIALTY_DETAIL_CODE: "CS028",

  // 투여경로 C009
  CONSTANT_ROUTE_CODE: "C009",

  // 판매사 C003, C057
  CONSTANT_DISTRIBUTER_CODE: ["C003", "C057"],

  // ATC C005
  CONSTANT_ATC_CODE: "C005",
  CONSTANT_ATC_234_CODE: "C030",
  CONSTANT_ATC_4_CODE: "C031",

  // Generic C013
  CONSTANT_GENERIC_CODE: "C013",

  // PatientType C016
  CONSTANT_PATIENTTYPE_CODE: "C016",

  // 시장 C020
  CONSTANT_MARKET_CODE: "C020",

  // 1회 투약량 C024
  CONSTANT_ONEQUANTITY_CODE: "C024",
  // 1회 투약량 세부 코드 CS041
  CONSTANT_ONEQUANTITY_DETAIL_CODE: "CS047",

  // 1회 투약횟수
  CONSTANT_PERDAY_CODE: "C025",
  // 1회 투약횟수 세부 코드 CS041
  CONSTANT_PERDAY_DETAIL_CODE: "CS048",

  // 총 처방일수
  CONSTANT_TOTALDAY_CODE: "C026",
  // 총 처방일수 세부 코드 CS041
  CONSTANT_TOTALDAY_DETAIL_CODE: "CS049",

  // 기준제품 C036 (Combination Ⅱ )
  CONSTANT_BASE_PRODUCT_CODE: "C036",

  // 병용제품 C037 (Combination Ⅱ )
  CONSTANT_COMBI_PRODUCT_CODE: "C037",

  // 기준성분 C038 (Combination Ⅱ )
  CONSTANT_BASE_MOLECULE_CODE: "C038",

  // 병용성분 C039 (Combination Ⅱ )
  CONSTANT_COMBI_MOLECULE_CODE: "C039",
  // 병용 성분 전체 CS125 (Combination Ⅱ )
  CONSTANT_COMBI_MOLECULE_TOTAL_CODE: "CS125",
  // 병용 성분(용량) 전체 CS124 (Combination Ⅱ )
  CONSTANT_COMBI_MOLECULE_VOLUME_CODE: "CS124",

  // 기준ATC C040 (Combination Ⅱ )
  CONSTANT_BASE_ATC_CODE: "C040",

  // 병용ATC C041 (Combination Ⅱ )
  CONSTANT_COMBI_ATC_CODE: "C041",

  // 기준질병 C042 (Combination Ⅱ )
  CONSTANT_BASE_DIAGNOSIS_CODE: "C042",

  // 병용질병 C043 (Combination Ⅱ )
  CONSTANT_COMBI_DIAGNOSIS_CODE: "C043",

  // 기준 항목 코드(Combination Ⅱ )C036 C038 C040 C042
  CONSTANT_BASE_ITEM_CODES: ["C036", "C038", "C040", "C042"],

  // 병용 항목 코드(Combination Ⅱ )C037 C039 C041 C043
  CONSTANT_COMBI_ITEM_CODES: ["C037", "C039", "C041", "C043"],

  // Combination Ⅱ 기본 ROW 컨텐츠 기준제품 C036 / 병용제품 C037
  CONSTANT_COMBI2_ROW_CODES: ["C036", "C037"],

  //연령/ 1회 투약량 / 1회 투약횟수 / 총 처방일수 커스텀 데이터 입력
  CONSTANT_CUSTDATA_INPUT_CODES: ["C017", "C024", "C025", "C026", "C059"],

  //실제예측 C064
  CONSTANT_REALESTIMATE_CODE: "C064",

  // HCD 종별 C066
  CONSTANT_HCD_CHANNEL_CODE: "C066",

  // HCD 지역 C068
  CONSTANT_HCD_REGIONS_CODE: "C068",

  // HCD 시장 C071
  CONSTANT_HCD_MARKET_CODE: "C071",

  // HCD Switching 기간
  CONSTANT_SWITCHING_PERIOD_CODE: "C074",
  // HCD 월 CS007 코드
  CONSTANT_SWITCHING_MONTH_PERIOD_CODES: "CS179",
  // HCD 분기 CS006
  CONSTANT_SWITCHING_QUARTER_PERIOD_CODES: "CS180",

  // CL 진료과 C083
  CONSTANT_CL_SPECIALTY_CODE: "C083",
  // CL 연령 C084
  CONSTANT_CL_AGE_CODE: "C084",
  // CL 성별 C085
  CONSTANT_CL_GENDER_CODE: "C085",
  // CL 신환여부 C086
  CONSTANT_CL_PATIENTTYPE_CODE: "C086",
  // CL 초재진 C087
  CONSTANT_CL_VISITTYPE_CODE: "C087",

  // CL 제품패턴 C088
  CONSTANT_CL_PRODUCTTYPE_CODE: "C088",
  // CL 질병패턴 C089
  CONSTANT_CL_DIAGNOSISTYPE_CODE: "C089",
  // CL 검사패턴 C090
  CONSTANT_CL_TESTTYPE_CODE: "C090",



  // 전체 추가 컨텐츠 코드
  // 급여구분 C010 종별 C011 병상 C012 PatientType C016 접종구분 접종차수 (클리닉 확인 필요 )
  CONSTANT_CONTENT_TOTAL_CODES: ["C010", "C011", "C012", "C016", "C064" /*"C050", "C051",*/ ],


  // ATC,제품(약품),성분,Generic
  CONSTANT_SUM_2OVER_NOT_CODES: ["C005", "C004", "C006", "C013"],

  // 가로축에 전체를 넣을 수 없는 컨텐츠
  // 성분,제품(약품)
  CONSTANT_COLS_TOTAL_NOT_CODES: ["C006", "C004"],

  // Option Modal 사용
  // 전체 OC001
  CONSTANT_OPTION_TOTAL_CODE: "OC001",
  // 전체 빈값보기 O001
  CONSTANT_OPTION_TOTAL_NULL_CODE: "O001",
  // 전체 총합계보기 O002
  CONSTANT_OPTION_TOTAL_SUM_CODE: "O002",

  // 브랜드 약품 OC002
  CONSTANT_OPTION_BRAND_DRUG_CODE: "OC002",

  // 약품 OC003
  CONSTANT_OPTION_DRUG_CODE: "OC003",

  // 약품/Generic OC004
  CONSTANT_OPTION_DRUG_GENERIC_CODE: "OC004",

  // 결과값 OC005
  CONSTANT_OPTION_RESULT_CODE: "OC005",

  // 정렬방식 OC006
  CONSTANT_OPTION_SORT_CODE: "OC006",

  //Menucode
  CONSTANT_MENU_SALES_CODE: "S001",
  
  //Weekly  메뉴
  CONSTANT_MENU_WEEKLY_CODE: "S002",

  //Non-Reimbursement
  CONSTANT_MENU_NONREIMBURSEMENT_CODE: "S003",

  //GIS
  CONSTANT_MENU_GIS_CODE: "S004",

  //SoB 메뉴
  CONSTANT_MENU_SOB_CODE: "S005",

  //Combination Therapy 1 S006
  CONSTANT_MENU_COMBINATION_THERAPY_1_CODE: "S006",

  //Combination Therapy New S007
  CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE: "S007",

  //Combination Therapy 2 S008
  CONSTANT_MENU_COMBINATION_THERAPY_2_CODE: "S008",

  // Diagnosis I S009
  CONSTANT_MENU_DIAGNOSIS_1_CODE: "S009",

  // Generic S011
  CONSTANT_MENU_GENERIC_CODE: "S011",

  // Pattern-Product S012
  CONSTANT_MENU_PATTERN_PRODUCT_CODE: "S012",
  CONSTANT_MENU_PATTERN_MOLECULE_CODE: "S016",
  CONSTANT_MENU_PATTERN_ATC_CODE: "S017",

  CONSTANT_MENU_PATTERN_CODES: ["S012", "S016", "S017"],

  // Dosage S013
  CONSTANT_MENU_DOSAGE_CODE: "S013",

  // SoB2
  CONSTANT_MENU_SOB2_CODE: "S015",

  // SoB2
  CONSTANT_MENU_HANDLING_CODE: "S024",

  // SoB2
  CONSTANT_MENU_SWITCHING_CODE: "S025",

  // CL Diagnosis
  CONSTANT_MENU_CL_Diagnosis_CODE: "S026",

  // Pattern
  // Complex 성분 C032
  CONSTANT_MOLECULE_TAB_CODE: ["C032", "C067"],

  // Pattern 제품 C0288
  CONSTANT_PRODUCT_PATTERN_CODE: "C028",

  // Diagnosis 메뉴 질병 C035
  CONSTANT_DISEASE_PATTERN_CODE: "C035",

  // Diagnosis 메뉴 결과형식 CS071
  CONSTANT_RESULT_TYPE_CODE: "CS071",

  // 월간 트렌드 메뉴 코드 (Combination Therapy 1, Combination Therapy New, Diagnosis I )
  CONSTANT_MONTH_TREND_CODES: ["S006", "S007", "S009", "S012"],

  // 시장 정의
  CONSTANT_MARKETDEFINITIONLIST_CODE: "C020",
  // 질환명 및 포함된 Class Code
  CONSTANT_DISEASENAME_CODE: "CS054",
  CONSTANT_INCLUDEDCLASS_CODE: "CS058",

  //상급종합병원, 종합병원, 의원
  CONSTANT_HOSPITAL_CODES: ["1", "3", "11"],

  //상급종합병원, 종합병원
  CONSTANT_HOSPITAL2_CODES: ["1", "11"],

  //즐겨찾기 제외 메뉴
  CONSTANT_FAVORITE_NOT_MENU_CODES: ["main", "SC003", "S020", "S021"],

  // 검색 시 글자 제한 제거 (약국 ID, ATC)
  CONSTANT_EXCEPTION_SEARCH_TEXT: ["C063", "C005", "C040", "C041"],

  CONSTANT_MENU_VACCINE_SALES_CODE: 'S014',
  CONSTANT_VACCINE_SALES_BASE_ROWS_CODE: ['C047', 'C092'],

  CONSTANT_MENU_VACCINE_SALES_NEW_CODE: 'S027',
  // CONSTANT_VACCINE_SALES_NEW_BASE_ROWS_CODE: ['C047']


  // 처방량 Projection, 추가측정치
  //CONSTANT_PROJECTION_QTY_CODES: ["CS038","CS117" ],
  CONSTANT_PROJECTION_QTY_CODES: ["14","38","39","73","87" ],

};

CONSTANTS = {
  ...CONSTANTS,

  // 제품(약품) 합치기 시 분석 불가 컨테츠 코드
  // 제조사, 판매사, ATC, 성분, 식약처, 제형, 투여경로
  CONSTANT_PRODUCT_COMBI_NOT_CODES: [
    "C002",
    "C003",
    "C005",
    "C006",
    "C007",
    "C008",
    "C009",
  ],

  // Top / Bottom 분석 불가 컨텐츠 코드
  CONSTANT_TOP_BOTTOM_DISABLED: [CONSTANTS.CONSTANT_GENDER_CODE, CONSTANTS.CONSTANT_PERIOD_WEEKLY_CODE],
  // Top / Bottom 분석 가능 컨텐츠 코드
  CONSTANT_TOP_BOTTOM_ENABLED: [CONSTANTS.CONSTANT_SPECIALTY_CODE, CONSTANTS.CONSTANT_REGIONS_CODE],

  CONSTANT_PRODUCTS_CODE: [
    ...CONSTANTS.CONSTANT_PRODUCT_CODE,
    CONSTANTS.CONSTANT_BASE_PRODUCT_CODE,
    CONSTANTS.CONSTANT_COMBI_PRODUCT_CODE,
  ],
  CONSTANT_MOLECULES_CODE: [
    ...CONSTANTS.CONSTANT_MOLECULE_CODE,
    CONSTANTS.CONSTANT_BASE_MOLECULE_CODE,
    CONSTANTS.CONSTANT_COMBI_MOLECULE_CODE,
  ],
}

export default CONSTANTS;