import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";

export const useFavoritesStore = defineStore("favorites", {
  state: () => {
    const { t } = useI18n();

    return {
      userId: 'CE00C1B1-2B98-4B75-AC74-2EEE2D187ABE', // 임시
      t,

      loading: false,

      favoriteList: [],
      favoriteData: {},
      favoriteDept: [{ id: 0, top: 0 }],
      searchList: null,
      changedFavoriteList: [],
      removeFavoriteList: [],
      dragFavorite: null,
      overFavorite: null,

      contextMenu: {
        isShow: false,
        position: { x: 0, y: 0 },
        id: null,
        isFolder: false,
      },

      //FolderDialog (현재 미사용)
      isFolderDialogOpen: false,
      folderDialog: {
        maxWidthOption: "",
        title: "",
        event: "",
        parent: "",
        id: null,
      },

      //FavoritDialog (현재 미사용)
      isFavoriteDialogOpen: false,
      favoriteDialog: {
        maxWidthOption: "",
        title: "",
        event: "",
        parent: "",
        id: null,
        menuCode: null,
        payload: null,
      },

      isFavoriteModifyModalOpen: false,
      favoriteModifyDialog: {
        title: "",
        item: null,
        parentId: null,
      },
      openFolderIndexs: new Set([0]),
      newFolder: {
        parentId: null,
        title: "",
        isEdit: false,
      },

      isFavoriteSettingModalOpen: false,

      // Aside AlertDialog
      isAlertDialogOpen: false,
      alertDialog: {
        maxWidthOption: "",
        title: "",
        message: "",
        afterEvent: "",
      },

      // Aside ConfirmDialog
      isConfirmDialogOpen: false,
      confirmed: "",
      confirmDialog: {
        maxWidthOption: "",
        title: "",
        message: "",
        data1: "",
        data2: "",
      },
    };
  },
  actions: {
    // 즐겨찾기 목록 조회
    async fetchFavorites() {
      this.loading = true;
      try {
        this.favoriteDept = [{ id: 0, top: 0 }];
        this.contextMenu = {
          isShow: false,
          position: { x: 0, y: 0 },
          item: null,
        };

        const favoriteData = [];
        const response = await ubistApi.getFavoriteList(localStorage.getItem('productType'));
        if (!Array.isArray(response.data) || response.data.length === 0) {
          this.favoriteList = [];
        } else {
            this.favoriteList = response.data;
        }

        this.favoriteList.forEach(favorite => {
          const { parentId } = favorite;
          if (!favoriteData[parentId]) favoriteData[parentId] = { folder: [], favorite: [] };
          
          if (favorite.isFolder) {
            favoriteData[parentId].folder.push(favorite);
          } else {
            favoriteData[parentId].favorite.push(favorite);
          }
        });

        const root = {
          id: 0,
          parentId: 0,
          title: "즐겨찾기 전체",
          isFolder: true,
        }
        this.favoriteList.unshift(root);
        favoriteData[-1] = {
          folder: [root],
          favorite: [],
        };

        favoriteData.forEach(favorite => {
          favorite.folder.sort((a, b) => a.sequence - b.sequence);
          favorite.favorite.sort((a, b) => a.sequence - b.sequence);
          favorite.total = favorite.folder.length + favorite.favorite.length
        });

        const favoriteKeys = Object.keys(favoriteData);
        //console.log('favoriteKeys',favoriteKeys);
        favoriteKeys.forEach(key => {
          if (favoriteData[key].folder.length > 0) {
            favoriteData[key].folder.forEach(favorite => {
              if (favoriteKeys.includes(favorite.id.toString())) {
                favorite.children = favoriteData[favorite.id];
                favorite.children.folder.forEach(child => {
                  child.parent = favorite;
                  child.isAppFavorite = false;
                });
                favorite.children.favorite.forEach(child => {
                  child.parent = favorite;
                  child.isAppFavorite = false;
                });
              }
            });
          }
        });

        //App즐겨찾기의 경우 isAppFavorite속성을 할당함. 
        const appFavorites = this.favoriteList.filter(f => f.sequence === -99999);
        appFavorites.forEach(appFavorite => {
          this.appFavoriteInit(appFavorite);
        })
      
        // this.favoriteList.forEach(favorite => {
        //   // if (favorite.children) {

        //   // }
        //   favorite.parent = this.favoriteList.find(f => f.id === favorite.parentId);
        // });
        // console.log('favoriteData', favoriteData);

        this.changedFavoriteList = [];
        this.removeFavoriteList = [];
        this.favoriteData = favoriteData;

      } catch (error) {
        console.error("Error fetching Favorites data:", error);
      }
      setTimeout(() => {
        this.loading = false;
      }, 1);
    },
    appFavoriteInit(appFavorite){
      //console.log('appFavorite.children.folder.length:::',appFavorite.children?.folder.length);
      appFavorite.isAppFavorite = true;
      appFavorite.children?.folder.forEach(item => {
        item.isAppFavorite  = true;
        this.appFavoriteInit(item);
      });

      appFavorite.children?.favorite.forEach(item =>{
        item.isAppFavorite  = true;
      });
    },
    async fetchFavoritesByParentId(parent) {
      this.loading = true;
      try {
        console.log('fetchFavoritesByParentId', parent);
        const response = await ubistApi.getFavoriteListByParentId(parent.id?? 0);
        if (response.data != '')
        {
          parent.child = response.data.map(item => ({
            ...item,
            isShowList: false,
            child: []
          }));
        }
      } catch (error) {
        console.error("Error fetching Favorite By ParentId data:", error);
      }
      setTimeout(() => {
        this.loading = false;
      }, 1);
    },
    onClickAddModifyFavoritePrev(item, parentId) {
      if (item)
        this.favoriteDialog.title = this.t("label.edit_favorite_name");
      else
        this.favoriteDialog.title = this.t("label.set_favorite_name");
      
      this.favoriteDialog.item = item;
      this.favoriteDialog.parentId = parentId;
      this.isFavoriteDialogOpen = true;
    },
    async handleFavoriteDialogitem(item, openAlertDialog, afterEvent, afterCancel) {
      const { id, customerId, parentId, title, serviceCode, payload, isFolder, sequence } = item;

      const isCheck = await this.checkFavorite(item);
      console.log('handleFavoriteDialogitem', isCheck);
      if (isCheck) {
        if (id > 0) {
          this.putFavorite(id, customerId, parentId, title, serviceCode, payload, isFolder, sequence);
        } else {
          this.postFavorite(parentId, title, serviceCode, payload, isFolder, sequence);
        }
        return true;
      } else {
        if (isFolder) {
          openAlertDialog({
            message: this.t("message.favorites_folder_with_the_same_name_already_exists"),
            afterEvent: afterEvent,
            afterCancel: afterCancel,
          });
          // this.alertDialog.message = this.t("message.favorites_folder_with_the_same_name_already_exists");
          // this.isAlertDialogOpen = true;
        } else {
          openAlertDialog({
            message: this.t("message.favorites_with_the_same_name_already_exists"),
            afterEvent: afterEvent,
            afterCancel: afterCancel,
          });
          // 덮어쓰기 체크
          // this.confirmDialog.message = this.t("message.favorites_with_the_same_name_already_exists");
          // this.isConfirmDialogOpen = true;
          // this.confirmDialog.data1 = title;
          // this.confirmDialog.data2 = parentId;
        }
      }
    },
    handleOverWriteFavoriteDialog() {
      const result = this.confirmDialog.data1;
      const parentId = this.confirmDialog.data2;
      const { event, parent, id, menuCode, payload, isFolder } = this.favoriteModifyDialog;
      console.log('handleOverWriteFavoriteDialog', { parent, result, menuCode, payload });
      this.putFavorite(parent, id, parentId, result, menuCode, payload, isFolder); // 저장
      this.confirmDialog.data1 = ""; // 초기화
      this.confirmDialog.data2 = "";
      this.isFavoriteModifyModalOpen = false;
    },
    async checkFavorite(item) {
      const { id, customerId, parentId, title, serviceCode, payload, isFolder } = item;
      
      try {
        let data = { customerId: customerId, id, parentId, title, serviceCode, payload: null, isFolder };

        const response = await ubistApi.checkFavorite(data);
        return response.data === "Ok";
      } catch (error) {
        console.error("Error checkFavorite:", error);
      }
    },
    async postFavorite(parentId, title, serviceCode, payload, isFolder, sequence) {
      const fType = serviceCode == null? 2 : 1; // 1: 서비스, 2: 콘텐츠
      try {
        let data = {};
        if (isFolder)
        {
          data = { id: 0, customerId: null, parentId: parentId, isFolder: true, title: title, payload: null, sequence };
        }
        else
        {
          data = { id: 0, customerId: null, parentId: parentId, isFolder: false, title: title, serviceCode: serviceCode, type: fType, payload: payload, sequence };
        }

        // post data
        const response = await ubistApi.postFavorite(localStorage.getItem('productType'), data);
        this.fetchFavorites();
      } catch (error) {
        console.error("Error postFavorite:", error);
      }
    },
    async putFavorite(id, customerId, parentId, title, serviceCode, payload, isFolder, sequence) {
      const fType = serviceCode == null ? 2 : 1; // 1: 서비스, 2: 콘텐츠
      try {
        
        let data = {};
        if (isFolder)
        {
          data = { id: id, customerId: customerId, parentId: parentId, isFolder: isFolder, title: title, serviceCode: serviceCode, type: 0, payload: null, sequence };
        }
        else
        {
          data = { id: id, customerId: customerId, parentId: parentId, isFolder: isFolder, title: title, serviceCode: serviceCode, type: fType, payload: payload, sequence };
        }          
        // put data
        const response = await ubistApi.putFavorite(localStorage.getItem('productType'), data);
        this.fetchFavorites();
      } catch (error) {
        console.error("Error postFavorite:", error);
      }
    },
    onClickAddModifyFavorite(item, parentId) {
      if (item?.isFolder) // folder
      {
        if (item.id) // update
          this.favoriteModifyDialog.title = this.t("label.edit_folder_name");
        // else // add
        //   this.favoriteModifyDialog.title = this.t("button.add_folder");
      }
      else // favorite
      {
        if (item.id) // update
          this.favoriteModifyDialog.title = this.t("label.edit_favorite_name");
        else // add
          this.favoriteModifyDialog.title = this.t("button.add_favorites");
      }
      this.favoriteModifyDialog.item = item;
      this.favoriteModifyDialog.parentId = parentId;
      this.isFavoriteModifyModalOpen = true;
    },
    async deleteFavorite(id) {
      await ubistApi.deleteFavorite(id);
    },
    // // 폴더 목록 조회
    // async fetchFavoritesForIsFolder(isFolder) {
    //   try {
    //     const response = await ubistApi.getFavoriteListForFolder(isFolder);
    //     if (response.data != '')
    //     {
    //       this.folderList = response.data;
    //     }
    //   } catch (error) {
    //     console.error("Error fetching Favorite For IsFolder data:", error);
    //   }
    // },
    hideContextMenuItem() {
      this.contextMenu = {
        isShow: false,
        position: { x: 0, y: 0 },
        item: null,
      };
    },
  },
  getters: {
  },
});
