<template>
  <div class="modal" :class="{ 'is-open': isOpen }">
    <!-- <div class="modal-overlay" @click="closealt"></div> -->
    <div class="modal-overlay"></div>
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{ $t("title.popup_options") }}</h2>
      </div>
      <div class="modal-body">
        <div class="content-container">
          <div class="left-content">
            <div
              v-for="(category, index) in filteredCategories"
              :key="index"
              class="box"
            >
              <h3>
                {{
                  this.$i18n.locale == "en"
                    ? category.englishName || category.name + "(en)"
                    : category.name
                }}
              </h3>
              <span
                v-if="
                  category.code === constants.CONSTANT_OPTION_BRAND_DRUG_CODE ||
                  category.code === constants.CONSTANT_OPTION_DRUG_CODE
                "
                style="font-size: 14px; color: var(--danger-color)"
              >
                {{
                  this.$i18n.locale == "en"
                    ? category.englishName != ""
                      ? $t("label.option_notice1") +
                        category.englishName +
                        $t("label.option_notice2")
                      : $t("label.option_notice1") +
                        category.name +
                        "(en)" +
                        $t("label.option_notice2")
                    : category.name + $t("label.option_notice1")
                }}
              </span>
              <span
                v-if="
                  category.code === constants.CONSTANT_OPTION_DRUG_GENERIC_CODE
                "
                style="font-size: 14px; color: var(--danger-color)"
              >
                {{
                  this.$i18n.locale == "en"
                    ? category.englishName + $t("label.option_notice3") ||
                      category.name + "(en)" + $t("label.option_notice3")
                    : category.name + $t("label.option_notice3")
                }}
              </span>
              <div v-if="category.type === 1" class="left-options">
                <div v-for="option in category.options" :key="option.code">
                  <input
                    type="checkbox"
                    :id="option.code"
                    :value="option.code"
                    v-model="checkboxState[category.code]"
                  />
                  <label :for="option.code">
                    {{
                      this.$i18n.locale == "en"
                        ? option.englishName || option.name + "(en)"
                        : option.name
                    }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="right-content">
            <div
              v-for="(category, index) in radioCategories"
              :key="index"
              class="box"
              :class="{ 'wide-box': category.type === 2 }"
            >
              <h3>
                {{
                  this.$i18n.locale == "en"
                    ? category.englishName || category.name + "(en)"
                    : category.name
                }}
              </h3>
              <span
                v-if="
                  (category.code === category.code) ===
                  constants.CONSTANT_OPTION_SORT_CODE
                "
                style="font-size: 14px; color: var(--danger-color)"
              >
                {{ $t("label.option_notice4") }}
              </span>
              <div
                v-if="category.type === 2"
                class="right-options optionModalright"
              >
                <div class="rectangle-container">
                  <div
                    v-for="(option, index) in category.options"
                    :key="option.code"
                    class="rectangle-option"
                    :class="{
                      active: radioState[category.code] === option.code,
                    }"
                    @click="selectOption(category.code, option.code, index)"
                  >
                    <div class="rectangle-label">
                      {{
                        this.$i18n.locale === "en"
                          ? option.englishName || option.name + "(en)"
                          : option.name
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container">
          <!-- <button class="btn primary" @click="savealt">
            {{ $t("button.save") }}
          </button> -->
          <!-- 20230612 primary 클래스 추가 -->
          <button class="btn" @click="save">
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    @confirm="closeAlertDialog"
  />
  <ConfirmDialog
    v-if="isConfirmDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="confirmDialog.title"
    :message="confirmDialog.message"
    :data1="confirmDialog.data1"
    :data2="confirmDialog.data2"
    @confirmed="handleConfirmDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import constants from "@/data/constants.js";
import { ref, reactive, onMounted, computed, defineComponent, watch } from "vue";
import { storeToRefs } from "pinia";
import { useContentsStore } from "@/store/contents";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "OptionModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    parentMenu: {
      type: String,
      default: "",
    },
    parentMenuCode: {
      type: String,
      default: "",
    },
  },
  components: {
    AlertDialog,
    ConfirmDialog,
  },
  setup(props, { emit }) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };
    //ConfirmDialog
    const isConfirmDialogOpen = ref(false);
    const confirmed = ref(null);
    const confirmDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
      data1: "",
      data2: "",
    });

    const { t } = useI18n();

    const contentsStore = useContentsStore();
    const { selectOptions } = storeToRefs(contentsStore);

    const optionCategories = ref([]);
    const checkboxState = reactive({});
    const radioState = reactive({});

    const stateOptions = ref([]);

    const openModal = () => {
      emit("update:isOpen", true);
    };

    const selectOption = (categoryCode, optionCode, index) => {
      radioState[categoryCode] = optionCode;
    };

    watch([selectOptions], (pre, next) => {
      if (selectOptions.value === null) fetchData();
      if (pre != next) stateOptions.value = [...selectOptions.value];
    });

    watch([stateOptions], () => {
      const userRes = stateOptions.value;

      optionCategories.value.forEach((category) => {
        if (category.type === 1) {
          checkboxState[category.code] = [];
          category.options.forEach((option) => {
            const { code } = option;
            const value =
              userRes.length > 0
                ? userRes.find((o) => o.code === code)?.value ??
                  null
                : null;
            if (!value) return;

            checkboxState[category.code].push(code);
          });
        } else if (category.type === 2) {
          radioState[category.code] = category.options[0].code;
          category.options.forEach((option) => {
            const { code } = option;
            const value =
              userRes.length > 0
                ? userRes.find((o) => o.code === code)?.value ??
                  null
                : null;
            if (!value) return;

            radioState[category.code] = code;
          });
        }
      });
    });

    const fetchData = () => {
      const defaultOptions = [];
      optionCategories.value.forEach(oc => 
        oc.options.forEach(o => o.defaultValue && defaultOptions.push({ code: o.code, value: true }))
      );
      selectOptions.value = defaultOptions;
    };

    const savealt = async (event) => {
      // confirmDialog 업데이트
      confirmDialog.value.message = t("message.optionmodal_save");
      confirmDialog.value.data1 = event;
      confirmDialog.value.data2 = "save";
      isConfirmDialogOpen.value = true;
    };
    const save = async () => {
      /*추가한 코드*/
      const optionsArray = [
        // 체크박스와 라디오 버튼의 상태를 배열로 변환
        ...Object.entries(checkboxState).flatMap(([categoryCode, codesArray]) =>
          codesArray.map((code) => ({
            code,
            value: true,
          }))
        ),
        ...Object.entries(radioState).map(([categoryCode, code]) => ({
          code,
          value: true,
        })),
      ];

      //리포트 조회를 위하여 담기
      selectOptions.value = optionsArray;
      close();
    };

    const closealt = async (event) => {
      // confirmDialog 업데이트
      confirmDialog.value.message = t("message.optionmodal_no_save");
      confirmDialog.value.data1 = event;
      confirmDialog.value.data2 = "close";
      isConfirmDialogOpen.value = true;
    };
    const close = async () => {
      stateOptions.value = [...selectOptions.value];
      emit("update:isOpen", false);
    };

    const handleConfirmDialog = (event, code, value) => {
      if (value) {
        if (code === "close") {
          close();
        } else {
          save();
        }
      }
      isConfirmDialogOpen.value = false;
    };

    const filteredCategories = computed(() =>
      optionCategories.value.filter((category) => category.type === 1)
    );
    const radioCategories = computed(() =>
      optionCategories.value.filter((category) => category.type === 2)
    );

    onMounted(async () => {
      const res = await ubistApi.getContentsOption(props.parentMenuCode);
      optionCategories.value = res.data;

      if (selectOptions.value && selectOptions.value.length > 0) {
        stateOptions.value = [...selectOptions.value];
      } else {
        fetchData();
      }
    });

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //ConfirmDialog
      isConfirmDialogOpen,
      confirmed,
      confirmDialog,
      handleConfirmDialog,

      optionCategories,
      checkboxState,
      radioState,
      selectOptions,
      constants,
      filteredCategories,
      radioCategories,
      openModal,
      selectOption,
      fetchData,
      save,
      savealt,
      close,
      closealt,
    };
  },
});
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.modal.is-open {
  opacity: 1;
  pointer-events: auto;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  width: 80%;
  /* height: 72%; */
  max-width: 1200px;
  max-height: 80%;
  /* overflow-y: auto; */
  border-radius: 8px;
  overflow: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header > h2 {
  font-size: 18px;
  font-weight: 700;
}

.modal-body {
  margin-top: 5px;
}

.content-container {
  display: flex;
  background-color: #fff;
}

.left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: var(--border-line);
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: var(--border-line);
  margin-left: 5px;
}

.box {
  background-color: #fff;
  padding: 10px;
  margin: 5px;
}

.box > h3 {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

h3::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 20px;
}

.button-container .btn {
  padding-left: 32px;
  padding-right: 32px;
}

/*
button {
  width: 87px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;
  appearance: none;
  cursor: pointer;
  padding: 8px 12px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer; 
}

button:hover {
  background: #0056b3;
}
*/

.left-options {
  display: flex;
  flex-wrap: wrap;
}

.left-options > div {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}

.left-options > div label {
  display: inline-block;
  font-size: 14px;
  margin-left: 5px;
}

.left-options > div label,
.left-options > div input {
  cursor: pointer;
}

.right-options {
  display: flex;
}

.rectangle-container {
  display: flex;
  justify-content: space-between;
  width: 600px;
}

.rectangle-option {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 32px;
  background-color: #fff;
  border: var(--border-line);
  border-left-width: 0;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  transition: all 0.1s ease-in;
}

.rectangle-option:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}

.rectangle-option:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rectangle-option.active {
  background-color: #f1f1f1; /* Add this for grey background on active */
  font-weight: 700;
}

.rectangle-label {
  white-space: nowrap;
}
</style>
