import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import ubistApi from "@/lib/ubistApi";
import { useContentsStore } from "@/store/contents";

export const useCommonStore = defineStore("common", {
  state: () => {
    const { t } = useI18n();
    return {
      lang: "ko",
      loading: false,

      t,

      user: null,
      productType: null,
      customer: {
        startDate: "",
        endDate: "",
        serviceNames: "",
        partnerName: "",
        tel: "",
        impossibleExcel: false,
      },
      userServiceList: [],
      
      menuList: null,
      menuCode: null,
      serviceList: [],
      searchText: "",

      // aside
      openAsideKey: -1,

      // alert
      isAlertDialogOpen: false,
      alertDialog: {
        maxWidthOption: "",
        title: "",
        message: "",
        afterEvent: () => {},
        beforeEvent: () => {},
        afterCancel: null,
        beforeCancel: null,
      },

      isAlertDialogOpenTwoConfirm: false,
      alertDialogTwoConfirm: {
        maxWidthOption: "",
        title: "",
        message: "",
        confirmLabel: "",
        confirm2Label: "",
        afterConfirm: () => {},
        afterConfirm2: () => {},
      },
    };
  },
  actions: {
    async logout() {
      if (this.user)
        await ubistApi.getUserLogout().then(window.location.href = process.env.VUE_APP_TEAM_UBIST);
      
        window.location.href = process.env.VUE_APP_TEAM_UBIST;
    },
    //메뉴목록 조회
    async getMenuList(type) {
      const res = await ubistApi.getMenuList(type);
      this.menuList = res.data;
      // menulist에서 serviceList만 추출
      this.menuList.forEach(menu => {
        this.serviceList.push(...menu.services);
      });
      return res.data;
    },
    //검색어 길이 체크 검색 공통
    validationSearchText(search, length = 2) {
      let result = true;
      const regex = / /gi;
      let searchText = search.replace(regex, "");
      if (searchText.length < length) {
        result = false;
      }
      return result;
    },
    // 패턴 검색 공통
    async onPatternContentItem(groupContents, search) {
      const contentsStore = useContentsStore();
      for (var content in groupContents) {
        await contentsStore.onClickPatternContentItem(
          groupContents[content],
          search
        );
      }
    },
    //상세 검색을 위한
    onSearchContentModalItem(code, type, search) {
      const contentsStore = useContentsStore();
      contentsStore.onClickSearchContentModalItem(code, type, search);
    },
    //상세 검색 내 제품 검색을 위한
    onProductSearch(code, subContentCode, id) {
      const contentsStore = useContentsStore();
      contentsStore.onClickProductSearch(code, subContentCode, id);
    },
    openAlertDialog(alertDialog) {
      this.isAlertDialogOpen = true;
      this.alertDialog = alertDialog;
    },
    closeAlertDialog() {
      this.isAlertDialogOpen = false;
      this.alertDialog = {
        maxWidthOption: "",
        title: "",
        message: "",
        focus: "",
        afterEvent: () => {},
        beforeEvent: () => {},
        afterCancel: null,
        beforeCancel: null,
      };
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
  getters: {},
});
