<template>
  <!-- 20230612 스타일 추가 { -->
  <div
    class="condition"
    style="padding: 0; margin-left: 0; border-radius: 0; border: none"
  >
    <!-- } 20230612 스타일 추가 -->
    <div :class="{ source: true, active: showContent !== null }">
      <div class="source-content">
        <div class="left">
          <div class="search-bar">
            <div class="search-group">
              <input
                style="width: 44.58%"
                :placeholder="$t('label.search_word')"
                v-model="searchTextVal"
                type="text"
                @keydown.enter="fnSearch(showContent.code, showContent.type)"
              />
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearch(showContent.code, showContent.type)"
              >
                {{ $t("button.search") }}
              </button>
              <button
                class="btn"
                style="margin-left: 5px"
                @click="fnSearchClean(showContent.code, showContent.type)"
              >
                {{ $t("button.searchClean") }}
              </button>
            </div>
          </div>
          <SearchContent v-if="showContent?.list?.length > 0" 
            :multiple="true"
            :content="showContent" 
            :searchTextVal="searchTextVal"
            @change="onChange"
            @ondblclick="() => onClick()"
            @enter="() => onClick()"
            @onContextmenu="showContextMenuItem"
            />
          <div v-else class="search-content">
            <div class="search-content">
              <div class="search-content-sub">
                <div class="selected">
                  <div class="header">&nbsp;</div>
                  <select multiple class="select-empty">
                    <option></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="isContextMenuVisibleItem"
            class="context-menu"
            :style="{
              top: contextMenuPositionItem.y + 'px',
              left: contextMenuPositionItem.x + 'px',
            }"
          >
            <ul>
              <li @click="putItem($event)">
                <button id="contextPutItem">
                  {{ $t("button.add") }}
                </button>
              </li>
            </ul>
          </div>
          <div
            v-if="
              constants.CONSTANT_MONTH_TREND_CODES.includes(parentMenuCode) &&
              showContent.code === constants.CONSTANT_PERIOD_CODE
            "
            style="display: flex; justify-content: flex-end"
          >
            <div style="margin-top: auto">
              <input
                type="checkbox"
                id="monthTrend"
                :disabled="isTrendDisabled"
                v-model="checkedMonthTrend"
                @change="checkMonthTrend($event)"
                style="width: 20px; height: 20px"
              />
              <label
                for="monthTrend"
                style="font-weight: 500; font-size: 16px; vertical-align: top"
                >{{ $t("label.trend_view") }}</label
              >
            </div>
          </div>
        </div>
        <div class="center">
          <button class="sm-button" @click="onClick()">
            <span class="material-symbols-outlined">chevron_right</span>
            <span
              style="
                writing-mode: vertical-lr;
                text-orientation: upright;
                padding-top: 12px;
              "
              >{{ $t("button.put") }}</span
            >
          </button>
        </div>
        <ComplexSelected
          v-bind:selectedComponentIndex="selectedComponentIndex"
          v-bind:parentMenu="parentMenu"
          v-bind:parentMenuCode="parentMenuCode"
        />
      </div>
    </div>
  </div>

  <AlertDialog
    v-if="isAlertDialogOpen"
    :maxWidthOption="alertDialog.maxWidthOption"
    :title="alertDialog.title"
    :message="alertDialog.message"
    :focus="alertDialog.focus"
    @confirm="closeAlertDialog"
  />
</template>

<script>
import AlertDialog from "@/components/AlertDialog.vue";
import constants from "@/data/constants.js";
import { defineComponent, ref, reactive, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useContentsStore } from "@/store/contents";
import { useCommonStore } from "@/store/common";
import ComplexSelected from "@/components/pattern/ComplexSelected.vue";
import SearchContent from "@/containerss/SearchContent.vue";
import periodData from "@/data/periodData.js";
import periodCustomize from "@/data/periodCustomize.js";
import ubistApi from "@/lib/ubistApi";

export default defineComponent({
  name: "ComplexCondition",
  props: ["parentMenu", "parentMenuCode", "selectedComponentIndex"],
  components: {
    AlertDialog,
    ComplexSelected,
    SearchContent,
  },
  setup(props) {
    //AlertDialog
    const isAlertDialogOpen = ref(false);
    const alertDialog = ref({
      maxWidthOption: "",
      title: "",
      message: "",
    });
    const closeAlertDialog = () => {
      isAlertDialogOpen.value = false;
    };

    const { locale, t } = useI18n();
    const contentsStore = useContentsStore();
    const {
      showContent,
      selectedContent,

      searchText,
      sortInit,
      contents,
      patternContentIndex,
      patternSelected,
      complexSelectedItems,
      isTrendDisabled,
      trendChkVal,
      itemEditModeYn,
    } = storeToRefs(contentsStore);

    const checkedMonthTrend = ref(trendChkVal);

    // checkedMonthTrend.value != isTrendDisabled.value;

    const searchTextVal = ref(searchText);
    onMounted(() => {
      init();
    });
    watch([patternContentIndex], () => {
      init();
    });

    const init = async () => {
      searchTextVal.value = "";
      checkedMonthTrend.value = false;

      complexSelectedItems.value = [
          { 
            ...contents.value[patternContentIndex.value], 
            list: patternSelected.value[patternContentIndex.value] && patternSelected.value[patternContentIndex.value].length > 0 
              ? patternSelected.value[patternContentIndex.value][0].list : []
          },
        ];

      const code = contents.value[patternContentIndex.value].code;
      if ([constants.CONSTANT_MENU_HANDLING_CODE].includes(props.parentMenuCode) && code === 'C030'
        && patternSelected.value[0] && patternSelected.value[0].length > 0) {
        const subCode = patternSelected.value[0][0].list[0].code;
        const id = patternSelected.value[0][0].list[0].id;
        
        const response = await ubistApi.getMarketSubSearchContent(code, subCode, id);

        const list = [];
        response.data.forEach((content, idx) => {
          if (content.length === 0) return;
          const { id, name, englishName } = content[0];
          const { code } = contents.value[patternContentIndex.value].subContents[idx];
          list.push({ code, id, name, englishName, type: 0 });
        });
        list.reverse();

        const isAllSelected = complexSelectedItems.value[0].list.findIndex(l1 => list.find(l2 => l2.code === l1.code && l2.id === l1.id)) > -1;
        if (!isAllSelected) {
          for(var l of list) {
            const isSelected = complexSelectedItems.value[0].list.findIndex(l1 => l.code === l1.code && l.id === l1.id) > -1;
            if (!isSelected) {
              complexSelectedItems.value[0].list.push(l);
              break;
            }
          }
          
          patternSelected.value[patternContentIndex.value] = [
            {
              code: code, // contentCode
              isTrend: false,
              list: complexSelectedItems.value[0].list,
            }
          ];
        }
      }
    };

    //검색
    const fnSearch = (code, type) => {
      const commonStore = useCommonStore();
      const result = commonStore.validationSearchText(searchTextVal.value, constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code) ? 1 : 2);
      
      if (!result) {
        if (constants.CONSTANT_EXCEPTION_SEARCH_TEXT.includes(code)) alertDialog.value.message = t("message.validation_empty");
        else alertDialog.value.message = t("message.validation_length");

        isAlertDialogOpen.value = true;

        return;
      }
      contentsStore.onClickSearchContentItem(code, type, searchTextVal.value);
    };

    //검색지우기
    const fnSearchClean = (code, type) => {
      if (typeof code === "undefined") {
        searchTextVal.value = "";
        return;
      }
      const contentsStore = useContentsStore();
      if (showContent.value.search) {
        showContent.value = {
          cont: [],
          subContents: [],
          list: [],
        };
      }
      contentsStore.onClickPatternContent(code, type, false, 0);
    };

    const selectedList = ref([]);
    
    //컨텐츠 항목별 선택시
    const onChange = (_selectedList) => {
      selectedList.value = _selectedList;
      hideContextMenuItem();
    };

    //선택한 항목 담기
    const onClick = () => {
      console.log("PatternSelected - " + JSON.stringify(patternSelected.value));
      if (!patternSelected.value[patternContentIndex.value]) {
        patternSelected.value[patternContentIndex.value] = [];
      }

      if (!patternSelected.value[patternContentIndex.value][0]) {
        patternSelected.value[patternContentIndex.value][0] = {
          code: selectedList.value[0].pCode, // contentCode
          isTrend: checkedMonthTrend.value,
          list: [],
        };
      }

      const pSelected = patternSelected.value[patternContentIndex.value][0];
      const content = contents.value[patternContentIndex.value];

      if ((selectedList.value?.length ?? 0) === 0) {
        alertDialog.value.message = t("message.select_items");
        isAlertDialogOpen.value = true;
        return;
      }

      let itemCnt = 0;
      complexSelectedItems.value.forEach((pattern) => { itemCnt += pattern.list.length; });
      if (props.parentMenuCode !== constants.CONSTANT_MENU_CL_Diagnosis_CODE) {
        if (itemCnt + selectedList.value.length > 50) {
          alertDialog.value.message = t("message.max_50");
          isAlertDialogOpen.value = true;
          return;
        }
      }

      // Combi new에서 병용범위만 선택 시 년도/반기 선택 불가
      if (
        props.parentMenuCode === constants.CONSTANT_MENU_COMBINATION_THERAPY_NEW_CODE &&
        (patternSelected.value?.[0]?.[0]?.list.length == 0 && patternSelected.value?.[1]?.[0]?.list.length > 0) &&
        ["CS004", "CS005"].includes(selectedList.value[0].code)
      ) {
        alertDialog.value.message = t("message.only_quarterly_monthly_period");
        isAlertDialogOpen.value = true;
        return;
      }
      
      //MAT 하나만 선택 가능
      const contentList = pSelected.list.map((l) => {
        if (isNaN(l.id)) return l.id;
        const parts = l.id.split(":");
        return parts.length > 1 ? parts[0] : l.id;
      });
      const periodCustData = _.uniq([
        ...periodCustomize.years,
        ...periodCustomize.quarters,
        ...periodCustomize.months,
      ].map((l) => l.id));

      const difference = _.difference(contentList, periodCustData);

      if (contentList && difference.length !== contentList.length && contentList.length > 0) {
        alertDialog.value.message = t("message.analysis_mat_one");
        isAlertDialogOpen.value = true;
        return;
      }

      // 전체만 선택 가능
      if (selectedList.value.find(l => l.englishName === 'All') && (selectedList.value.length > 1 || pSelected.list.length > 0)
        || pSelected.list.find(l => l.englishName === 'All')) {
        alertDialog.value.message = t("message.select_all");
        isAlertDialogOpen.value = true;
        return;
      }


      const list = complexSelectedItems.value[0].list;
      const error = [];
      const errorEng = [];

      const msg = [];
      const msgEng = [];
      
      if (props.parentMenuCode !== constants.CONSTANT_MENU_HANDLING_CODE || selectedList.value[0].pCode !== 'C030') {
        if (list.length > 1 || (list.length === 1 && list[0].englishName !== 'All')) {
          if (list[0].code !== selectedList.value[0].code) {
            const content = contents.value[patternContentIndex.value];
            alertDialog.value.message = t("message.impossible_cross_selection", { content: locale.value == "en" ? content.englishName : content.name });
            isAlertDialogOpen.value = true;
            return;
          }
        }
      }

      selectedList.value.forEach((select) => {
        const { code, id, name, englishName, type } = select;
        if (!list.find((l) => l.code === code && l.id === id)) {
          list.push({ code, id, name, englishName, type });
          msg.push(name);
          msgEng.push(englishName);
        } else {
          error.push(name);
          errorEng.push(englishName);
        }

        //작업
        if (
          !constants.CONSTANT_TREND_MONTHLY_CODES.includes(select.code) ||
          select.parentId === "periodCust"
        ) {
          isTrendDisabled.value = true;
          checkedMonthTrend.value = false;
        } else {
          isTrendDisabled.value = false;
        }
      });
        
      if (error.length > 0) {
        let existItem = error.join(",");
        let newItem = msg.join(",");
        if (localStorage.getItem("lang") == "en") {
          existItem = errorEng.join(",");
          newItem = msgEng.join(",");
        }

        if (msg.length > 0) {
          alertDialog.value.message = t("message.select_newItem", {
            existItem,
            newItem,
          });
          isAlertDialogOpen.value = true;
        } else {
          alertDialog.value.message = t("message.validation_existsItem", {
            existItem,
          });
          isAlertDialogOpen.value = true;
        }
      }

      pSelected.list = list;

      if (pSelected.list.length !== 1 ||!constants.CONSTANT_TREND_MONTHLY_CODES.includes(pSelected.list[0].code)) {
        isTrendDisabled.value = true;
        checkedMonthTrend.value = false;
      }

      // if (props.parentMenuCode === constants.CONSTANT_MENU_CL_Diagnosis_CODE && [constants.CONSTANT_CL_SPECIALTY_CODE, constants.CONSTANT_CL_AGE_CODE].includes(complexSelectedItems.value[0].code)) {
      //   const psIdx = pSelected.list.findIndex(l => l.englishName === 'All');

      //   if (psIdx > -1) {
      //     pSelected.list.splice(psIdx, 1);
      //   }
      // } else {
      // }
    };

    const checkMonthTrend = (event) => {
      if (
        !patternSelected.value[patternContentIndex.value] ||
        patternSelected.value[patternContentIndex.value].length < 1
      ) {
        //작업
        alertDialog.value.message = t("message.select_period");
        isAlertDialogOpen.value = true;
        checkedMonthTrend.value = false;
        return;
      } else {
        patternSelected.value[patternContentIndex.value][0].isTrend =
          checkedMonthTrend.value;
      }
    };

    //오른쪽 마우스 기능
    const isContextMenuVisibleItem = ref(false);
    const contextMenuPositionItem = reactive({ x: 0, y: 0 });
    const isContextMenuButtonMore = ref(false);
    const showContextMenuItem = (event) => {
      isContextMenuVisibleItem.value = true;
      contextMenuPositionItem.x = event.clientX;
      contextMenuPositionItem.y = event.clientY;
    };

    const putItem = (event) => {
      event.preventDefault(); // 기본 동작을 중단시킵니다.
      onClick();
      hideContextMenuItem();
    };

    const hideContextMenuItem = () => {
      isContextMenuVisibleItem.value = false;
      isContextMenuButtonMore.value = false;
    };

    const isListDisabled = ref(false);
    const listIndex = ref(0);
    const onListDisabled = (event, idx, val) => {
      isListDisabled.value = val;
      listIndex.value = idx;
    };

    return {
      //AlertDialog
      isAlertDialogOpen,
      alertDialog,
      closeAlertDialog,
      //설정값
      constants,

      showContent,
      selectedContent,
      patternContentIndex,

      fnSearch,
      fnSearchClean,
      onChange,
      onClick,
      checkMonthTrend,
      checkedMonthTrend,
      isTrendDisabled,

      sortInit,
      selectedList,
      searchTextVal,
      contents,
      patternSelected,

      isListDisabled,
      listIndex,
      onListDisabled,

      itemEditModeYn,
      showContextMenuItem,
      putItem,
      isContextMenuVisibleItem,
      isContextMenuButtonMore,
      contextMenuPositionItem,
    };
  },
});
</script>

<style scoped>
.condition {
  flex: 1 1 0;
  height: 100%;
  /* padding: 10px 0 10px 10px; */ /* 20230704 수정 */
  margin-left: 10px;
  /* border: var(--border-line); 
  border-radius: var(--border-radius); */ /* 20230704 수정 */
  transition: all 0.3s;
}
.source {
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.source.active {
  display: flex;
}
.source-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.source-content .left {
  display: flex;
  flex-direction: column;

  flex: 1 1 auto;
  height: 100%;
}
.source-content .center {
  margin-top: 40px;
  display: flex;
  width: fit-content;
  /* height: 100%; */
  height: 88%;
  padding: 0 10px;
}
.sm-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  width: 26px;
  height: 86px;
  font-size: 13px;
  cursor: pointer;
  border: var(--border-line);
  border-radius: 26px;
  transform: translateY(-50%);
  box-shadow: 2px 2px 6px rgba(6, 10, 19, 0.1);
}
.sm-button .material-symbols-outlined {
  position: absolute;
  left: 50%;
  top: 4px;
  width: 18px;
  height: 18px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  border-radius: 50%;
  background-color: #545454;
  transform: translateX(-50%);
}
/* 20230612 추가 */
.sm-button:hover {
  background-color: #f5f5f5;
}
.search-bar {
  /*width: 103%;*/
  display: flex;
  justify-content: space-between;
}
.search-bar .search-group {
  display: flex;
  width: 100%;
}
.search-content {
  display: flex;
  flex: 1 1 auto;
  padding: 5px 0px;
}
.search-content-sub {
  flex: 1 1 auto;
  width: 100%;
}
.search-content-sub + .search-content-sub {
  margin-left: 5px;
}
.selected {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.selected .header {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 4px 16px;
  background-color: #f5f5f5;
  border: var(--border-line);
  border-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  /* 목록 펼치고 접기 0623 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected .header > span {
  display: inline-block;
  margin-left: 4px;
}
.selected .header > span.material-symbols-outlined {
  font-size: 26px;
  color: #b9b9b9;
}
.selected select {
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  border: var(--border-line);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.selected select:focus {
  /* border: 1px solid #999; */
  outline: none;
}
.selected select option {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 4px 6px;
  margin: 1px 0;
}
.selected select option:checked {
  border-radius: 4px;
  background: #e1e8f6 linear-gradient(0deg, #e1e8f6 0%, #e1e8f6 100%);
}
/* select 선택시 라인 없애기  */
select:focus {
  outline: none;
}
/* 목록 펼치고 접기 0623 */

.listDisabled {
  width: 3%;
}
.button-wide-open {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-open .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}
.button-wide-close {
  position: absolute;
  right: 5px;
  height: 18px;
  z-index: 10;
}
.button-wide-close .material-symbols-outlined {
  font-size: 18px;
  color: #fff;
}

/* 마우스 우클릭 */
.context-menu {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #666;
  padding: 5px 0;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(6, 10, 19, 0.06);
}
.context-menu ul {
  width: 80px;
}
.context-menu li {
  display: flex;
  cursor: pointer;
  padding-left: 15px;
}
.context-menu li:hover {
  background-color: #efefef;
}

.context-menu li button {
  font-size: 12px;
  height: 23px;
  line-height: 1;
}
</style>
